import '../css/App.css';

function Btn(props) {
    return (
        <li>
            <a href={props.index} className="font-sans flex items-center p-[8.5px] text-sm font-medium text-[#868686] dark:text-[#868686] hover:bg-[#1A1B1D] dark:hover:bg-[#1A1B1D] focus:bg-[#1A1B1D] dark:focus:bg-[#1A1B1D]">
                {props.value}
            </a>
        </li>
    );
}

export default Btn;