import '../css/App.css';

import bg1 from '../assets/beams.webp';
import bg2 from '../assets/grid.svg';

const bgBeams = {
  backgroundImage: `url(${bg1})`,
};
const bgGrid ={
  backgroundImage: `url(${bg2})`,
};

function BackGround() {
    return (
        <div style={bgBeams} className={`absolute h-full w-full bg-cover bg-center bg-fixed`}>
          <div style={bgGrid} className="absolute h-full w-full [background-position:calc(100%+5px)_calc(100%+5px)] bg-fixed" />
        </div>
    );
  }
  
  export default BackGround;