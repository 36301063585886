import logo from '../assets/logo.png';
import '../css/App.css';

function NavMenu() {
    return (
      <div className="flex transition-colors duration-500">
        <div className="max-w-8xl mx-auto">
          <div className="py-4 lg:px-8 mx-4 lg:mx-0">
            <div className="relative flex items-center">
              <a className="mr-3 flex-none overflow-hidden w-auto" href="/">
                <span className="sr-only">
                  Goahub home page
                </span>
                <img 
                  className="w-[108px] h-[45px]" 
                  loading="lazy"
                  alt="logo"
                  src={logo}
                />
              </a>
            </div>
          </div>
        </div>
      </div>  
    );
  }
  
  export default NavMenu;