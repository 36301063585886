import { useEffect, useState, useRef } from "react";
import React from 'react';
import "../css/App.css";
import banner from '../assets/channels4_banner.jpg';
import { Helmet } from 'react-helmet-async';
import AdSense from 'react-adsense';


const announce_text = `
공지가 없어요.
`;

function AnnounceView(props) {
  return (
      <>
      <Helmet>
          <title>{props.title}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta property="og:image" content="https://api.goarosa.live/thumbnails/main.png"/>
          <meta property="og:site_name" content="GOA HUB"/>
          <meta property="og:title" content={"GOA HUB" + props.title}/>
          <meta property="og:description" content={props.title}/>
      </Helmet>
      <div className="sm:pt-[38px] sm:ml-64">
        <AdSense.Google
          client='ca-pub-9910390024008129'
          slot='5711376467'
          style={{ display: 'block' }}
          layout='in-article'
          format='auto'
          responsive='true'
        />
        {/* <img src={banner} className="w-full h-auto"/> */}
        <div className="rounded-lg">
          <div className="relative mx-auto px-4 sm:px-6 md:px-8 pb-16">
            <main>
              <div className="grid grid-flow-row sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 py-4">
                <div className="grid-rows-1">
                  <div>
                  </div>
                </div>
                <div className="grid-rows-2">
                  <section className="pt-[60px] py-[91px]">
                    <div className="justify-center mx-auto max-w-screen-xl px-4">
                        <div className="justify-between mb-25">
                            <ul className="grid">
                                <li className="flex justify-between">
                                  <h1 className="text-3xl font-bold text-[#ffffff]">{props.title}</h1>
                                </li>
                                <div className="w-full h-[1px] bg-[#262626] mb-3 mt-3"/>
                                <li className="flex justify-between">
                                  <p className="text-sm text-[#868686]">{announce_text}</p>
                                </li>
                                <li className="justify-between">
                                  
                                </li>
                                <li>
                                  
                                </li>
                            </ul>
                        </div>
                    </div>
                  </section>
                </div>
                <div className="w-full h-[1px] bg-[#262626]"></div>
                <section className="py-[71px] px-4 md:px-24">
                    <div className="justify-center mx-auto max-w-screen-xl">
                        <div className="justify-between mb-25 md:px-24">
                        <ul className="grid">
                            <li className="justify-between">
                                <h2 className="text-xs text-center text-[#ffffff]">
                                    <a href='/'>GOAHUB</a>™. All Rights Reserved.</h2>
                                <h2 className="text-xs text-center text-[#ffffff]">Async • Kamunari</h2>
                            </li>
                            
                        </ul>
                        </div>
                    </div>
                </section>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnnounceView;