import { useEffect, useState } from "react";
import Videos from "./components/Videos";
import "./css/App.css";
import banner from './assets/channels4_banner.jpg';
import Logo from './assets/logo.png';

import { LockClosedIcon } from '@heroicons/react/20/solid'

function Login() {
  const [password, setPassword] = useState("");

  const onClick = () => {
    localStorage.setItem('password', password);
    alert('패스워드 설정 성공')
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
    console.log(password);
    onClick();
    }
  };

  const handleOnChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <>
      <div className="pt-[38px] sm:ml-64">
        <div className="rounded-lg">
          <div className="relative mx-auto px-4 sm:px-6 md:px-8 pb-16">
            <main>
              <div className="grid grid-flow-row sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 py-4">
                <div className="grid-rows-1">
                  <div>
                  </div>
                </div>
                <div className="pt-[190px] grid-rows-2">
                  <>
                    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                      <div className="w-full max-w-md space-y-8">
                        <div>
                          <img
                            className="mx-auto h-12 w-auto"
                            src={Logo}
                            alt="Your Company"
                          />
                        </div>
                        <form className="mt-8 space-y-6">
                          <input type="hidden" name="remember" defaultValue="true" />
                          <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                              <label htmlFor="password" className="sr-only">
                                Password
                              </label>
                              <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className=" dark:bg-[#1A1A1B] bg-[#1A1A1B] relative block w-full appearance-none rounded-lg border border-[#2A2A2B] px-3 py-2 text-[#868686]  placeholder-[#868686]  focus:z-10 focus:border-[#5A5A5B] focus:outline-none focus:ring-[#5A5A5B] sm:text-sm"
                                placeholder="Password"
                                onChange={handleOnChange}
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              type="button"
                              onClick={onClick}
                              className="group relative flex w-full justify-center rounded-md border border-transparent bg-[#ff9900] py-2 px-4 text-sm font-semibold text-black hover:bg-[#FFA723]"
                            >
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <LockClosedIcon className="h-5 w-5 text-black" aria-hidden="true" />
                              </span>
                              Login
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                </div>
                <div className="w-full h-[1px] bg-[#262626]"></div>
                <section className="py-[71px] px-4 md:px-24">
                    <div className="justify-center mx-auto max-w-screen-xl">
                        <div className="justify-between mb-25 md:px-24">
                        <ul className="grid">
                            <li className="justify-between">
                                <h2 className="text-xs text-center text-[#ffffff]">
                                    <a href='/'>GOAHUB</a>™. All Rights Reserved.</h2>
                                <h2 className="text-xs text-center text-[#ffffff]">Async • Kamunari</h2>
                            </li>
                        </ul>
                        </div>
                    </div>
                </section>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
