import { useEffect, useState, useRef } from "react";
import React from 'react';
import "./css/App.css";
import banner from './assets/channels4_banner.jpg';
import { Helmet } from 'react-helmet-async';
import AdSense from 'react-adsense';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';

const TimeConverter = ({ timeString }) => {  
  let t = new Date(Date.parse(timeString));
  // t.setMinutes(t.getMinutes() - 2);
  // t.setSeconds(t.getSeconds() + 10);
  return t.toLocaleString();
};

const Reply = ({
  reply,
  setCurrentParentId,
  setIsReplyOpen,
  isReplyOpen,
  currentParentId,
  handleSubmit,
  content,
  setContent,
  onTimeClick
}) => {
  const contentWithTimeLinks = (content) => {
    const regex = /\b([0-2]?[0-9]:[0-5][0-9]:[0-5][0-9])\b/g;
    const parts = content.split(regex);

    return parts.map((part, index) => 
      regex.test(part) ? 
        <span key={index} style={{ color: 'blue', cursor: 'pointer' }} onClick={() => onTimeClick(part)}>{part}</span> : 
        part
    );
  };
return (
  <div className="bg-[#1B1B1B] rounded-md ml-6 my-1 overflow-y-auto md:ml-6 pt-4 pl-4">
    <div className="flex justify-between items-center">
      <h4 className="text-sm font-bold">{reply.user.username}</h4>
      <p className="text-xs text-gray-500 mr-4">
        <TimeConverter timeString={`${reply.created_at}`} />
      </p>
    </div>
    <p
      className="mt-2 text-sm"
      style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
    >
       {contentWithTimeLinks(reply.content)}
       {/* //{reply.content} */}
    </p>
    <button
      className="text-xs font-semibold text-blue-500 mt-2"
      onClick={() => {
        setCurrentParentId(reply.comment_id);
        setIsReplyOpen(true);
      }}
    >
      reply
    </button>
    {isReplyOpen && currentParentId === reply.comment_id && (
      <ReplyForm
        handleSubmit={handleSubmit}
        content={content}
        setContent={setContent}
        setIsReplyOpen={setIsReplyOpen}
      />
    )}
    {reply.replies.map((nestedReply) => (
      <Reply
        key={nestedReply.comment_id}
        reply={nestedReply}
        setCurrentParentId={setCurrentParentId}
        setIsReplyOpen={setIsReplyOpen}
        isReplyOpen={isReplyOpen}
        currentParentId={currentParentId}
        handleSubmit={handleSubmit}
        content={content}
        setContent={setContent}
        onTimeClick={onTimeClick}
      />
    ))}
  </div>
);
};

const CommentComponent = ({
  comment,
  setCurrentParentId,
  setIsReplyOpen,
  isReplyOpen,
  currentParentId,
  handleSubmit,
  content,
  setContent,
  onTimeClick
}) => {
  const contentWithTimeLinks = (content) => {
    const regex = /\b([0-2]?[0-9]:[0-5][0-9]:[0-5][0-9])\b/g;
    const parts = content.split(regex);

    return parts.map((part, index) => 
      regex.test(part) ? 
        <span key={index} style={{ color: 'blue', cursor: 'pointer' }} onClick={() => onTimeClick(part)}>{part}</span> : 
        part
    );
  };
  
  return (
    <div className="rounded-md mb-1 p-4">
      <div className="flex justify-between items-center">
        <h4 className="text-sm font-bold">{comment.user.username}</h4>
        <p className="text-xs text-gray-500">
          <TimeConverter timeString={comment.created_at} />
        </p>
      </div>

      {/* if content has time string (like HH:MM:SS), use onTimeClick */}



      <p
        className="mt-2 text-sm"
        style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
      >
        {contentWithTimeLinks(comment.content)}
      </p>
      <button
        className="text-xs font-semibold text-blue-500 mt-2"
        onClick={() => {
          setCurrentParentId(comment.comment_id);
          setIsReplyOpen(true);
        }}
      >
        reply
      </button>
      {isReplyOpen && currentParentId === comment.comment_id && (
        <ReplyForm
          handleSubmit={handleSubmit}
          content={content}
          setContent={setContent}
          setIsReplyOpen={setIsReplyOpen}
        />
      )}
      {comment.replies.map((reply) => (
        <Reply
          key={reply.comment_id}
          reply={reply}
          setCurrentParentId={setCurrentParentId}
          setIsReplyOpen={setIsReplyOpen}
          isReplyOpen={isReplyOpen}
          currentParentId={currentParentId}
          handleSubmit={handleSubmit}
          content={content}
          setContent={setContent}
          onTimeClick={onTimeClick}
        />
      ))}
    </div>
  );
};

const ReplyForm = ({ handleSubmit, content, setContent, setIsReplyOpen }) => {
  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="답글"
          className="w-full p-2 rounded-md border-2 border-gray-300"
        />
        <div className="flex justify-between items-center gap-5 mt-2 md:gap-5">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-md"
          >
            작성
          </button>
          <button
            type="button"
            onClick={() => {
              setIsReplyOpen(false);
              setContent("");
            }}
            className="w-full bg-gray-500 text-white py-2 rounded-md"
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};

const CommentSection = ({ videoIndex, onTimeClick }) => {
  const [content, setContent] = useState("");
  const [commentContent, setCommentContent] = useState("");
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const [currentParentId, setCurrentParentId] = useState(null);
  const sortReplies = (comments) => {
    const repliesMap = new Map();
    const sortedComments = comments
      .filter((comment) => {
        if (comment.parent_id) {
          if (repliesMap.has(comment.parent_id)) {
            repliesMap.get(comment.parent_id).push(comment);
          } else {
            repliesMap.set(comment.parent_id, [comment]);
          }
          return false;
        }
        return true;
      })
      .map((comment) => {
        comment.replies = repliesMap.get(comment.comment_id) || [];
        return comment;
      });

    comments.forEach((comment) => {
      comment.replies = repliesMap.get(comment.comment_id) || [];
    });

    return sortedComments;
  };

  useEffect(() => {
    fetch(
      `https://api.goarosa.live/comments?video_index=${videoIndex}&after_index=0`
    )
      .then((res) => res.json())
      .then((data) => {
        setComments(sortReplies(data.comments));
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [videoIndex]);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("https://api.goarosa.live/comments", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        content,
        video_index: videoIndex,
        parent_id: currentParentId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Success") {
          setContent("");
          setIsReplyOpen(false);
          fetch(
            `https://api.goarosa.live/comments?video_index=${videoIndex}&after_index=0`
          )
            .then((res) => res.json())
            .then((data) => {
              setComments(sortReplies(data.comments));
              setIsLoading(false);
            })
            .catch((error) => console.error(error));
        } else {
          alert("답글 등록에 실패했습니다.");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();

    fetch("https://api.goarosa.live/comments", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        content: commentContent,
        video_index: videoIndex,
        parent_id: "0",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Success") {
          setCommentContent("");
          fetch(
            `https://api.goarosa.live/comments?video_index=${videoIndex}&after_index=0`
          )
            .then((res) => res.json())
            .then((data) => {
              setComments(sortReplies(data.comments));
              setIsLoading(false);
            })
            .catch((error) => console.error(error));
        } else {
          alert("댓글 등록에 실패했습니다.");
        }
      })
      .catch((error) => console.error(error));
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <form onSubmit={handleSubmit1} className="rounded-md">
        <textarea
          value={commentContent}
          onChange={(e) => {
            setCommentContent(e.target.value);
            console.log(e.target.value);
          }}
          required
          maxLength={256}
          className="w-full border bg-slate-800 text-stone-300 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          rows="4"
        ></textarea>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md mt-2 mb-4"
        >
          댓글 작성
        </button>
      </form>
      <AdSense.Google
          client='ca-pub-9910390024008129'
          slot='5711376467'
          style={{ display: 'block' }}
          layout='in-article'
          format='auto'
          responsive='true'
        />
      <div className="mt-[30px]">
        {comments.map((comment) => (
          <CommentComponent
            key={comment.comment_id}
            comment={comment}
            setCurrentParentId={setCurrentParentId}
            setIsReplyOpen={setIsReplyOpen}
            isReplyOpen={isReplyOpen}
            currentParentId={currentParentId}
            handleSubmit={handleSubmit}
            content={content}
            setContent={setContent}
            onTimeClick={onTimeClick}
          />
        ))}
      </div>
    </>
  );
  };

const Comment = ({ videoIndex , onTimeClick }) => {
  return (
    <div className="container mx-auto my-8 px-4 md:px-0 text-[#616161]">
      <h2 className="text-xl font-bold mb-4">댓글</h2>
      <p className="mb-4"> 댓글 내용이 부적절할 경우, 관리자에 의해 삭제될 수 있습니다.</p>
      <p className="mb-4"> 작성한 댓글은 수정 및 삭제가 불가능합니다.</p>
      <CommentSection videoIndex={videoIndex} onTimeClick={onTimeClick}/>
    </div>
  );
};

const convertTimeToSeconds = (time) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

function VideoView(props) {
  const [videoInfo, setVideoInfo] = useState({});
  const playerRef = useRef(null);
  const [isWindow, setIsWindow] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [video_url, setvideo_url] = useState("");
  const [hasHls, sethasHls] = useState(false);

  const handleTimeClick = (timeString) => {
    const timeInSeconds = convertTimeToSeconds(timeString);
    if (playerRef.current) {
      playerRef.current.seekTo(timeInSeconds);
    }
  };

  useEffect(() => {
    setIsWindow(true);
    const requestOptions = {
      method: 'GET',
      "Content-Type": 'application/x-www-form-urlencoded',
      mode: 'no-cors'
    };

    fetch(`https://api.goarosa.live/get_hls/${props.index}`)
        .then(response => response.text())
        .then(result => { 
          let path = JSON.parse(result);
          setvideo_url(path.hls_path); 
          console.log(path.hls_path);
        })
        .catch(error => console.log('error'. error));

    
    fetch(`https://api.goarosa.live/count/${props.index}`, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error'. error));
      
    fetch(`https://api.goarosa.live/videoInfo/${props.index}`)
        .then(response => response.text())
        .then(result => { setVideoInfo(JSON.parse(result));})
        .catch(error => console.log('error'. error));
  }, []);

  const handleClick = async () => {
    if (!playerRef.current) {
      alert('영상을 먼저 재생해주세요.');
      return;
    }

    const formattedStartTime = prompt("시작 시간을 입력하세요. (예시: 00:00:00)");
    const formattedEndTime = prompt("종료 시간을 입력하세요. (최대 60초, 예시: 00:01:00)");
    const title = prompt("클립의 제목을 입력하세요.");

    if (formattedEndTime === null || title === null) {
      return;
    }

    const videoIndex = props.index; // 예시
    const apiUrl = `https://api.goarosa.live/makeClip?video_index=${videoIndex}&start_time=${formattedStartTime}&end_time=${formattedEndTime}&title=${title}`;

    try {
      const response = await fetch(apiUrl);

      if (response.status === 200) {
        alert('클립 생성 요청 성공. 잠시 후 클립이 생성됩니다.');
      } else {
        alert('클립 생성 실패');
      }
    } catch (error) {
      console.error('API 호출 중 오류 발생:', error);
    }
  };

  return (
      <>
      <Helmet>
          <title>{props.title}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta property="og:image" content="https://api.goarosa.live/thumbnails/main.png"/>
          <meta property="og:site_name" content="GOA HUB"/>
          <meta property="og:title" content={"GOA HUB" + props.title}/>
          <meta property="og:description" content={props.title + " 영상 다시보기"}/>
          <meta property="og:type" content="video.other"/>
          <meta property="og:image" content={"https://api.goarosa.live/v1/thumbnails/" + props.index}/>
          <meta property="og:image:secure_url" content={"https://api.goarosa.live/v1/thumbnails/" + props.index}/>
          <meta property="og:image:type" content="image/png"/>
          <meta property="og:image:width" content="1280"/>
          <meta property="og:image:height" content="720"/>
          <meta property="og:url" content={"https://api.goarosa.live/view/thumbnails/" + props.index}/>
          <meta property="og:video" content={"https://api.goarosa.live/view/thumbnails/" + props.index}/>
          <meta property="og:video:url" content={"https://api.goarosa.live/view/thumbnails/" + props.index}/>
          <meta property="og:video:secure_url" content={"https://api.goarosa.live/view/thumbnails/" + props.index}/>
          <meta property="og:video:type" content="video/mp4"/>
          <meta property="og:video:width" content="1280"/>
          <meta property="og:video:height" content="720" />
      </Helmet>
      <div className="sm:pt-[38px] sm:ml-64">
        <AdSense.Google
          client='ca-pub-9910390024008129'
          slot='5711376467'
          style={{ display: 'block' }}
          layout='in-article'
          format='auto'
          responsive='true'
        />
        {/* <img src={banner} className="w-full h-auto"/> */}
        <div className="rounded-lg">
          
          <div className="relative mx-auto px-4 sm:px-6 md:px-8 pb-16">
            <main>
              <div className="grid grid-flow-row sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
                <div className="grid-rows-1">
                  <section className="pt-[60px] py-[51px]">
                    <div className="justify-center mx-auto max-w-screen-xl px-4">
                        <div className="justify-between mb-25">
                        <ul className="grid">
                          <li className="justify-between">
                            <div className={`flex`}>
                              <ReactPlayer
                                ref={playerRef}
                                url={video_url}
                                controls
                                playing={isPlaying}
                                width={"100%"}
                                height={"100%"}
                                className={`z-20 rounded-[5px] block align-middle border-0 object-cover overflow-hidden`}
                              />
                            </div>
                            </li>
                              <li className="flex justify-between">
                                <div className="flex justify-start">
                                  <img src="https://yt3.ggpht.com/A741F7xyCY5MTXQ0BmU7yk3ECGXsGEs_jGzepMx88RjFSGCty2bCxCupnzLxvL2U-hsdykOVIA=s176-c-k-c0x00ffffff-no-rj-mo" className="w-[40px] h-[40px] rounded-full mt-[18px] mr-1"/>
                                    <h2 className="text-center text-[16px] md:text-[21px] text-[#616161] font-bold mt-[22px]">
                                      {props.title}
                                    </h2>
                                  </div>
                                  <div className="mt-[22px]">
                                    <button
                                      onClick={handleClick}
                                      className="bg-zinc-700 hover:bg-zinc-600 text-white font-bold p-1 px-4 rounded-md"
                                    >
                                      클립 생성
                                    </button>
                                  </div>
                                </li>
                                <li className="justify-between">
                                  <p className="text-[#616161] text-sm mt-[12px] mb-3">{`조회수 ${videoInfo["viewcount"]}회 • ${videoInfo["date"]}`}</p>
                                </li>
                                <li>
                                  <div className="w-full h-[1px] bg-[#262626] mb-3 mt-3"/>
                                  <Comment videoIndex={props.index} onTimeClick={handleTimeClick}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                  </section>
                </div>
                <div className="w-full h-[1px] bg-[#262626]"></div>
                <section className="py-[71px] px-4 md:px-24">
                    <div className="justify-center mx-auto max-w-screen-xl">
                        <div className="justify-between mb-25 md:px-24">
                        <ul className="grid">
                            <li className="justify-between">
                                <h2 className="text-xs text-center text-[#ffffff]">
                                    <a href='/'>GOAHUB</a>™. All Rights Reserved.</h2>
                                <h2 className="text-xs text-center text-[#ffffff]">Async • Kamunari</h2>
                            </li>
                            
                        </ul>
                        </div>
                    </div>
                </section>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoView;