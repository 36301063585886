import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './css/index.css';
import App from './App';
import Banner from './Banner';
import NavMenu from './components/NavMenu';
import Btn from './components/Button';
import BackGround from './components/Background';
import reportWebVitals from './reportWebVitals';
import logo from './assets/logo.png';
import Main from './Main';
import { HelmetProvider } from 'react-helmet-async';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <HelmetProvider>
                <Main />
            </HelmetProvider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
