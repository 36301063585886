import { useEffect, useState } from "react";
import Videos from "./components/Videos";
import Audios from "./components/Audios";
import "./css/App.css";
import banner from './assets/channels4_banner.jpg';

import AdSense from 'react-adsense';

function Separator() {
  return (
    <div className="w-full h-[1px] bg-[#262626]"></div>
  );
}

function RecentCommentList() {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      fetch('https://api.goarosa.live/recent_comments')
          .then(res => res.json())
          .then(data => {
              setComments(data.comments);
              setLoading(false);
          })
          .catch(err => console.log(err));
  }, []);

  if (loading) { return <div>Loading...</div> }

  return (
      <ul role="list" className="divide-y divide-[#262626]">
      {comments.map((comment) => (
          <li key={comment.content} className="flex flex-col sm:flex-row justify-between gap-x-6 py-3">
            <div className="flex gap-x-4">
                {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-[#797777]">{comment.user.username}</p>
                  <p className="mt-1 truncate text-xs leading-5 text-[#bdb9b9]">{comment.content}</p>
                </div>
            </div>
            <div className="mt-4 sm:mt-0 sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-[#bdb9b9] hover:text-blue-500 hover:underline">
                  <a href={`/watch?index=${comment.video_index}`}>
                      {comment.video_title}
                  </a>
                </p>
                <p className="mt-1 text-xs leading-5 text-[#918f8f]">
                  {comment.created_at}
                </p>
            </div>
          </li>
      ))}
      </ul>
  )
}

function App(state) {
  const [sortFlag, setSortFlag] = useState(0);
  const [sortFlag2, setSortFlag2] = useState(0);
  const [videoList, setVideoList] = useState([]);
  const [clipVideoList, setClipVideoList] = useState([]);

  const sorter = (videolist) => {
      // newest first
      if (sortFlag === 0)
          return videolist.sort((a, b) => b["originalDate"] - a["originalDate"]);
      // views
      else if (sortFlag === 1)
          return videolist.sort((a, b) => b["view"] - a["view"]);
      // oldest first
      else if (sortFlag === 2)
          return videolist.sort((a, b) => a["originalDate"] - b["originalDate"]);
  }

  const sorter2 = (videolist) => {
      // newest first
      if (sortFlag === 0)
          return videolist.sort((a, b) => b["originalDate"] - a["originalDate"]);
  }

  useEffect(() => {
    if (state.state != 100) {
      const requestOptions = {
        method: 'GET',
        "Content-Type": 'application/x-www-form-urlencoded'
      };
      fetch(`https://api.goarosa.live/getFileList?type=${state.state}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            const sortedList = sorter(result["list"]);
            setVideoList(sortedList);
        })
        .catch(error => console.log('error'.error));
      
      
      fetch(`https://api.goarosa.live/getFileList?type=3`, requestOptions)
        .then(response => response.json())
        .then(result => {
            // sort clips
            const sortedList = sorter2(result["list"]);
            // only show 5 clips
            sortedList.splice(5);
            setClipVideoList(sortedList);
        })
        .catch(error => console.log('error'.error));
    }
    else {
      const requestOptions = {
      method: 'GET',
      "Content-Type": 'application/x-www-form-urlencoded'
      };
      fetch(`https://api.goarosa.live/searchFile?q=${state.keyword}`, requestOptions)
      .then(response => response.json())
      .then(result => setVideoList((result["list"])))
      .catch(error => console.log('error'.error));
    }
  }, [sortFlag]);

  return (
    <div >
      <div className="sm:pt-[38px] sm:ml-64">
        {/* <img
          src={banner}
          className="w-full h-auto"
        /> */}
        <AdSense.Google
          client='ca-pub-9910390024008129'
          slot='5711376467'
          style={{ display: 'block' }}
          layout='in-article'
          format='auto'
          responsive='true'
        />
          <div className="rounded-lg">
            <div className="pt-[38px] relative mx-auto px-4 sm:px-6 md:px-8 pb-16">
              <main>
                <li className="justify-between">
                  <h1 className="text-[16px] md:text-[21px] text-white font-bold">최근 댓글</h1>
                </li>
                <li className="pb-[13px] gap-x-2 md:gap-x-4">
                  <RecentCommentList />
                </li>
                <Separator/>
                <li className="justify-between pt-4">
                  <h1 className="text-[16px] md:text-[21px] text-white font-bold">최신 클립</h1>
                </li>
                <div className="grid grid-flow-row sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 py-4">
                  <div className="grid-rows-1">
                    <div>
                    </div>
                  </div>
                  <div className="grid-rows-2">
                    <Videos list={clipVideoList}></Videos>
                  </div>
                </div>
                <Separator/>
                <li className="justify-between pt-4">
                  <h1 className="text-[16px] md:text-[21px] text-white font-bold">다시보기</h1>
                </li>
                <div className="pt-[38px] flex text-[16px] md:text-[16px]">
                  <button 
                    className='bg-[#353535] hover:bg-[#303030] focus:bg-[#303030] text-white ring-1 ring-[#272727] rounded-tl-lg rounded-bl-lg py-[2px] px-2'
                    onClick={() => setSortFlag(0)}    
                  >
                    최신순
                  </button>
                  <button className='bg-[#353535] hover:bg-[#303030] focus:bg-[#303030] text-white ring-1 ring-[#272727] py-[2px] px-2'
                    onClick={() => setSortFlag(1)}    
                  >
                    조회수순
                  </button>
                  <button className='bg-[#353535] hover:bg-[#303030] focus:bg-[#303030] text-white ring-1 ring-[#272727] rounded-tr-lg rounded-br-lg py-[2px] px-2'
                    onClick={() => setSortFlag(2)}    
                  >
                    오래된순
                  </button>
                </div>
              
                <div className="grid grid-flow-row sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 py-4">
                  <div className="grid-rows-1">
                    <div>
                    </div>
                  </div>
                
                  <div className="grid-rows-2">
                    {
                      state.state === 4
                      ? <Audios list={videoList} />
                      : <Videos list={videoList} />
                    }
                  </div>
                  <div className="w-full h-[1px] bg-[#262626]"></div>
                  
                  <section className="py-[71px] px-4 md:px-24">
                    <div className="justify-center mx-auto max-w-screen-xl">
                      <div className="justify-between mb-25 md:px-24">
                        <ul className="grid">
                          <li className="justify-between">
                              <h2 className="text-xs text-center text-[#ffffff]">
                              <a href='/'>GOAHUB</a>™. All Rights Reserved.</h2>
                            <h2 className="text-xs text-center text-[#ffffff]">Async • Kamunari</h2>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </main>
            </div>
          </div>
        </div>
    </div>      
  );
}

export default App;
